<template>
  <v-row v-if="user" class="mb-4" no-gutters>
    <v-app-bar
      style="border-bottom: 1px solid #eceff1; background-color: white"
      app
      flat
    >
      <v-row no-gutters justify="center">
        <v-col sm="10" md="9" lg="8" xl="6" xs="12">
          <v-row
            align="center"
            justify="space-between"
            color="transparent"
            dark
            flat
          >
            <img
              v-if="appTheme"
              class="ml-2"
              style="height:25px; object-fit: contain;"
              draggable="false"
              :src="appTheme.lightBgHeader"
            />
            <profile-menu />
            <v-chip
              v-if="!user && tempAuthExpiration && !isMobile"
              label
              color="red"
              align-left
              dark
            >
              <span class="mr-2" v-if="!isMobile">Access Expires: </span>
              {{ getDateAndTimeFromTimestamp(tempAuthExpiration) }}
            </v-chip>
          </v-row>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-col>
      <!--Mobile Patient Switcher-->
      <v-row no-gutters justify="center">
        <v-col sm="10" md="9" lg="8" xl="6" xs="12">
          <v-row
            cols="12"
            v-if="userPatients && userPatients.length > 1"
            class="flex-nowrap"
            style="overflow-x: auto; border-bottom: 1px solid #eceff1;"
            no-gutters
          >
            <v-card color="transparent" flat>
              <v-row no-gutters class="px-0">
                <v-card
                  color="transparent"
                  flat
                  class="ma-3 pt-2 mr-4"
                  v-for="(patientHere, index) in userPatients"
                  :key="index"
                >
                  <div
                    class="mb-1"
                    style="border: 2px solid #bdbdbd; border-radius:50%; padding:2px;"
                  >
                    <v-btn
                      color="grey lighten-1"
                      small
                      fab
                      dark
                      depressed
                      @click="gotToPatient(patientHere)"
                    >
                      <span
                        class="font-weight-bold text-button"
                        style="font-size: 10px;"
                      >
                        {{ patientHere.firstName.charAt(0)
                        }}{{ patientHere.lastName.charAt(0) }}
                      </span>
                    </v-btn>
                  </div>
                  <div
                    class="pa-0 ma-0 text-caption"
                    style="text-align: center; "
                  >
                    {{ patientHere.firstName }}
                  </div>
                </v-card>
              </v-row>
            </v-card>
          </v-row>
        </v-col>
      </v-row>

      <v-row no-gutters justify="center">
        <v-col sm="10" md="9" lg="8" xl="6" xs="12">
          <view-as-patient v-if="patient" />
        </v-col>
      </v-row>
    </v-col>

    <!--Desktop Patient Switcher-->

    <!-- <v-row v-if="!isMobile">
    <v-col xs="12" sm="6" md="4" lg="4" xl="2">
      <viewer-patient-header-mobile style="position: sticky; top: 75px;" v-show="patient" class=""/>
      
    </v-col>
    <v-col xs="12" sm="6" md="8" lg="8" xl="10" class="flex-grow-1 flex-shrink-0">
      <omni-chart v-if="patient" />
    </v-col>
  </v-row> -->

    <!-- <v-flex v-else >
      <v-row v-if="!user && tempAuthExpiration && isMobile" no-gutters class="px-2 pb-1 pt-2">
    <v-card color="yellow" flat outlined class="pa-2" style=" width: 100%; overflow: hidden;" >
      <v-row fill-height no-gutters style="flex-wrap: nowrap;">
      <v-col
        cols="1"
        style="
          min-width: 60px;
          max-width: 60px;
          margin-right: 1px solid #f5f5f5;
        "
        class="flex-grow-0 flex-shrink-0"
        id="mydiv"
      >
      <v-row no-gutters class="mx-0" row>
      
           <v-icon class="mr-3" large>mdi-alert-circle</v-icon>
           <v-divider vertical/>
      </v-row>
      <v-spacer/>
      </v-col>

      <v-col
        cols="11"
        style="
          min-width: 10px;
        "
        class="flex-grow-1 flex-shrink-1"
      >
      <v-card-text
        class="font-weight-medium text-p3 pa-0 ma-0"
      >
        Temporary access to this chart will expire on <span>{{getConversationalDateAndTimeFromTimestamp(tempAuthExpiration) }}. For permanent access, reach out to the sender.</span>
      </v-card-text>
      
      </v-col>
      
         </v-row> 
      </v-card>
      </v-row>
      <viewer-patient-header-mobile v-if="patient" class=""/>
      <omni-chart v-if="patient" />
    </v-flex> -->
  </v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex'
//import OmniChart from '../components/OmniChart.vue'
import HelperMixin from '@/core/mixins/HelperMixin'
import ProfileMenu from '../components/ProfileMenu.vue'
//import ViewerPatientHeaderMobile from '../components/ViewerPatientHeaderMobile.vue';
//import PatientCombinedCharts from '../components/PatientCombinedCharts.vue';
//import PatientInfoDetail from '../components/PatientInfoDetail.vue';
import ViewAsPatient from './ViewAsPatient.vue'

export default {
  name: 'PatientFacingView',
  mixins: [HelperMixin],
  data() {
    return {
      adlsDone: false,
      filesDone: false,
      showConditions: true,
      expandMetrics: false,
      dropActive: false,
      dragEnter: 0,
      dropArea: null,
      redDoor: false,
      gettingUserPatients: false,
    }
  },
  components: {
    //OmniChart,
    ProfileMenu,
    //ViewerPatientHeaderMobile,
    //PatientCombinedCharts,
    //PatientInfoDetail,
    ViewAsPatient,
  },
  computed: {
    ...mapState('patient', [
      'patients',
      'patient',
      'files',
      'isSearchingChart',
      'chartSearchResults',
      'chartSearchTerm',
      'isLoadingFiles',
      'patientLogs',
    ]),
    ...mapState('taskboard', ['userPatients']),
    ...mapState('provider', ['localProviderList']),
    ...mapState('auth', [
      'user',
      'rpmPartner',
      'appTheme',
      'partner',
      'configuration',
      'viewerMode',
      'tempAuthExpiration',
    ]),
    patientSwitchBarColor() {
      if (!this.isMobile) {
        return 'transparent'
      }
      return 'navColor'
    },
    navBarColor() {
      if (!this.isMobile) {
        return null
      }
      return 'navColor'
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown || false
    },
    showChartNotes() {
      if (this.partner && this.configuration) {
        if (this.configuration) {
          if (this.configuration.chartNotesOnVitals) {
            return true
          }
        }
      }
      return false
    },
  },
  methods: {
    ...mapActions('auth', ['userActivity']),
    ...mapActions('patient', [
      'getPatient',
      'patientDataAccessLog',
      'clearPatient',
    ]),
    ...mapActions('taskboard', ['getUserPatients']),
    ...mapActions('file', ['addDroppedFiles']),
    gotToPatient(patientHere) {
      this.clearPatient()
      this.getPatient({ id: patientHere._id }).then(() => {})
    },
    displayNewChartWelcome() {
      this.$refs.newChartWelcome.open({
        data: {
          firstName: this.patient.firstName,
          lastName: this.patient.lastName,
        },
      })
    },
    exportData() {
      this.$refs.exportData.open({
        displayedMetrics: this.displayedMetrics,
      })
    },
    editMetadata(file) {
      this.$refs.editMetadata.open({
        fileToEdit: file,
      })
    },
    fileSelected(file) {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.openSelectedFileInNewWindowMobile(file)
      } else {
        this.openSelectedFileInNewWindowDesktop(file)
      }
    },
  },
  watch: {
    userPatients(val) {
      this.gettingUserPatients = false
      if (val.length > 0) {
        this.getPatient({ id: val[0]._id }).then(() => {})
      }
    },
  },
  mounted() {
    if (this.$route.query.redDoor) {
      this.redDoor = true
    }
    // this.getUserPatients({ user: this.user._id });
    if (this.patient) {
      setTimeout(() => (this.expandMetrics = true), 600)
    }

    /// drag and drop code
    this.dropArea = document.getElementById('app')
    let respondToDragEvents = ['dragenter', 'dragover', 'drop']
    let unhighlightEvents = ['dragleave']
    respondToDragEvents.forEach(eventName => {
      this.dropArea.addEventListener(eventName, this.respondToDrag, false)
    })
    unhighlightEvents.forEach(eventName => {
      this.dropArea.addEventListener(eventName, this.unhighlight, false)
    })
    this.dropArea.addEventListener('dragenter', this.dragEnterCount, false)
    this.dropArea.addEventListener('dragleave', this.dragExitCount, false)
    this.dropArea.addEventListener('drop', this.dragDrop, false)
    // handle folders in chrome and firefox
  },
  beforeDestroy() {
    this.dropArea = document.getElementById('app')
    let respondToDragEvents = ['dragenter', 'dragover', 'drop']
    let unhighlightEvents = ['dragleave']
    respondToDragEvents.forEach(eventName => {
      this.dropArea.removeEventListener(eventName, this.respondToDrag, false)
    })
    unhighlightEvents.forEach(eventName => {
      this.dropArea.removeEventListener(eventName, this.unhighlight, false)
    })
    this.dropArea.removeEventListener('dragenter', this.dragEnterCount, false)
    this.dropArea.removeEventListener('dragleave', this.dragExitCount, false)
    this.dropArea.removeEventListener('drop', this.dragDrop, false)
  },
}
</script>

<style>
.fileDropContainer {
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.637);
}

.fileDropContent {
  color: black;
  opacity: 0.2;
  font-weight: 700;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: white;
  margin-left: 2px;
  margin-right: 2px;
  border-radius: 4px;
  font-size: 12px;
}
</style>
